import * as React from 'react';
import { Redirect } from "react-router";
import * as _ from "lodash";
import { BlogPosts } from "../../datasource/blog";
import Helmet from "react-helmet/es/Helmet";
import './BlogDetailPage.css';
import { Link } from "react-router-dom";

export class BlogDetailPage extends React.Component {
  blogPostHead;

  render() {
    const postIndex = _.findIndex(BlogPosts, { 'slug': this.props.match.params.slug });
    if (postIndex === -1)
      return <Redirect to="/error-404"/>;

    const post = BlogPosts[postIndex]

    return (
      <main className="BlogDetailPage">
        <Helmet>
          <title>{post.title} — Martin Gondowijoyo</title>
          <meta name="description" content={post.summary}/>
        </Helmet>
        <nav className="BlogPost__breadcrumbs">
          <ul>
            <li className="BlogPost__breadcrumb"><Link to="/">Home</Link></li>
            <li className="BlogPost__breadcrumb"><Link to="/blog">Blog</Link></li>
            <li className="BlogPost__breadcrumb BlogPost__breadcrumb--active">This article</li>
          </ul>
        </nav>
        <article className="BlogPost__article">
          <header className="BlogPost__header" ref={(ref) => this.blogPostHead = ref}>
            <h1 className="BlogPost__heading">{post.title}</h1>
            <p className="BlogPost__summary">{post.summary}</p>
            <p className="BlogPost__timestamp">Written on {post.timestamp} by Martin Gondowijoyo</p>
          </header>
          <section className="BlogPost__content" dangerouslySetInnerHTML={this.createMarkup(post.content)}/>
        </article>
        <nav className="BlogPost__footer-navigations">
          {this.createFooterPrevNavigation(postIndex, BlogPosts.length)}
          {this.createFooterNextNavigation(postIndex)}
        </nav>
      </main>
    );
  }

  createMarkup = (source) => {
    return { __html: source }
  }

  createFooterPrevNavigation = (postIndex, postCount) => {
    if (postIndex < postCount - 1)
      return (
        <div className="BlogPost__footer-nav">
          <Link to={'/blog/' + BlogPosts[postIndex + 1].slug}>
            <table className="BlogPost__footer-nav-inner-table">
              <tbody>
              <tr>
                <td className="BlogPost__footer-nav-arrow-column"><p>←</p></td>
                <td>
                  <p className="BlogPost__footer-nav-indicator">Previous article</p>
                  <p className="BlogPost__footer-nav-title">{BlogPosts[postIndex + 1].title}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </Link>
        </div>
      )
  }

  createFooterNextNavigation = (postIndex) => {
    if (postIndex > 0)
      return (
        <div className="BlogPost__footer-nav BlogPost__footer-nav--right">
          <Link to={'/blog/' + BlogPosts[postIndex - 1].slug}>
            <table className="BlogPost__footer-nav-inner-table">
              <tbody>
              <tr>
                <td>
                  <p className="BlogPost__footer-nav-indicator">Next article</p>
                  <p className="BlogPost__footer-nav-title">{BlogPosts[postIndex - 1].title}</p>
                </td>
                <td className="BlogPost__footer-nav-arrow-column"><p>→</p></td>
              </tr>
              </tbody>
            </table>
          </Link>
        </div>
      )
  }
}
