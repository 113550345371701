import * as React from 'react';
import './AboutPage.css';
import Helmet from "react-helmet/es/Helmet";
import { ContactCard } from "../components/ContactCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";

export function AboutPage() {
  const [scrollY, setScrollY] = useState(window.scrollY || 0);
  useEffect(() => {
    const scrollHandler = () => {
      setScrollY(window.scrollY);
    }
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  })
  let introText = 'Martin is a software development engineer based in Tokyo, Japan.';
  return (
    <main className="AboutPage">
      <Helmet>
        <title>About — Martin Gondowijoyo</title>
        <meta name="description" content={introText}/>
      </Helmet>
      <div className="AboutPage__container">
        <div className="AboutPage__contact-container AboutPage__contact-container--mobile" style={{
          transform: `rotate(${ Math.min(scrollY / 4, 10) - 2 }deg) translate(${ scrollY }px, -${scrollY / 2}px)`,
          opacity: `${ 1 - scrollY / 200}`
        }}>
          <ContactCard/>
        </div>
        <div className="AboutPage__contact-container">
          <ContactCard/>
        </div>
        <div className="AboutPage__contact-offset"/>
        <div className="AboutPage__body-text">
          <h1 className="AboutPage__header">Hi there.</h1>
          <p className="AboutPage__text">
            Martin is a software development engineer, currently working for Rakuten Japan as a front-end engineer.
          </p>
          <p className="AboutPage__text">
            He has over 5 years of experience working with distributed, scalable, multi-tiered
            projects—some serving more than thousands of users daily.
          </p>
          <div className="AboutPage__social-container">
            <a className="AboutPage__social" href="https://www.linkedin.com/in/mgondowijoyo" target="_blank" rel="nofollow noreferrer noopener">
              <FontAwesomeIcon icon={faLinkedinIn}/>
            </a>
            <a className="AboutPage__social" href="https://www.instagram.com/mgondowijoyo" target="_blank" rel="nofollow noreferrer noopener">
              <FontAwesomeIcon icon={faInstagram}/>
            </a>
          </div>
        </div>
      </div>
    </main>
  )
}
