import * as React from 'react';
import { BrowserRouter, useLocation } from "react-router-dom";
import { Route, Switch } from "react-router";

import './App.css';
import { AboutPage } from "./pages/AboutPage";
import { Error404Page } from "./pages/Error404Page";
import { BlogPage } from "./pages/blog/BlogPage";
import { BlogDetailPage } from "./pages/blog/BlogDetailPage";
import ScrollToTop from "./components/navigational/ScrollToTop";
import { NavigationBar } from "./components/navigational/NavigationBar";
import { FooterBar } from "./components/navigational/FooterBar";

function App() {

  return (
    <div className="App">
      <div className="Main">
        <BrowserRouter>
          <ScrollToTop/>
          <Route>
            <RoutedApp/>
          </Route>
        </BrowserRouter>
      </div>
    </div>
  );
}

function RoutedApp() {
  const location = useLocation();
  return (
    <>
      <NavigationBar currentPath={location.pathname}/>
      <div className="Content">
        <Switch location={location}>
          <Route exact path="/" component={AboutPage}/>
          <Route exact path="/about" component={AboutPage}/>
          <Route exact path="/blog" component={BlogPage}/>
          <Route path="/blog/:slug" component={BlogDetailPage}/>
          <Route component={Error404Page}/>
        </Switch>
      </div>
      <FooterBar/>
    </>
  )
}

export default App;
