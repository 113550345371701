import * as React from 'react';
import './ContactCard.css';

export class ContactCard extends React.Component {
  render() {
    const CONTACT_QR_URL = "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=BEGIN:VCARD%0AN:Martin%20Gondowijoyo%0AEMAIL:mgondowijoyo@gmail.com%0AURL:https://mgondowijoyo.com%0AEND:VCARD";
    return (
      <div className="ContactCard">
        <p className="ContactCard__header">Martin Gondowijoyo</p>
        <p className="ContactCard__subheader">Front-end engineer</p>
        <figure className="ContactCard__qr-container">
          <img
            className="ContactCard__qr"
            src={CONTACT_QR_URL}
            alt="QR Code for adding Martin detail to contact."
          />
        </figure>
        <table className="ContactCard__table">
          <tbody>
          <tr>
            <td><a href="mailto:mgondowijoyo@gmail.com">mgondowijoyo@gmail.com</a></td>
            <td>
              <a
                className="ContactCard__email"
                href="https://linkedin.com/in/mgondowijoyo"
                target="_blank"
                rel="noopener noreferrer">
                  linkedin.com/in/mgondowijoyo
              </a>
            </td>
          </tr>
          <tr>
            <td><p>Tokyo, Japan</p></td>
            <td/>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
