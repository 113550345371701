import { Link } from "react-router-dom";
import './FooterBar.css';

export function FooterBar() {
  return (
    <>
      <div className="Footer">
        <nav className="Footer__sitemap">
          <p>Sitemap</p>
          <ul>
            <li className="Footer__sitemap-link-container">
              <Link className="Footer__sitemap-link" to="/about">
                About
              </Link>
            </li>
            <li className="Footer__sitemap-link-container">
              <Link className="Footer__sitemap-link" to="/blog">
                Blog
              </Link>
            </li>
          </ul>
        </nav>
        <footer>
          &copy;{ new Date().getFullYear() } Martin Gondowijoyo. All rights reserved.
        </footer>
      </div>
    </>
  )
}