import * as React from 'react';
import './BlogPage.css';
import { BlogPosts } from "../../datasource/blog";
import { Link } from "react-router-dom";
import Helmet from "react-helmet/es/Helmet";

export class BlogPage extends React.Component {
  render() {
    let introText = 'Hi there! I’m a software engineer with emphasis on front-end engineering who strive to build responsive and highly-performant user-oriented products. This is where I discuss software engineering and design tips (or rant).';
    return (
      <main className="BlogPage">
        <Helmet>
          <title>Blog — Martin Gondowijoyo</title>
          <meta name="description" content={introText}/>
        </Helmet>
        <h1>Blog posts</h1>
        <ul className="BlogPosts">
          {this.renderPosts()}
        </ul>
      </main>
    )
  }

  renderPosts = () => {
    return BlogPosts.map((post) => (
      <li className="BlogPosts__list" key={post.slug}>
        <Link to={'blog/' + post.slug}>
          <div className="BlogPosts__body">
            {this.renderThumbnail(post.thumbnail, post.thumbnailCaption)}
            <div className="BlogPosts__text">
              <h2 className="BlogPosts__title">
                {this.renderMobileThumbnail(post.thumbnail, post.thumbnailCaption)}
                <span>{post.title}</span>
              </h2>
              <p className="BlogPosts__summary">{post.summary}</p>
              <ul className="BlogPosts__tags">{this.renderTags(post.tags)}</ul>
              <p className="BlogPosts__timestamp">Written on {post.timestamp} by Martin Gondowijoyo</p>
            </div>
          </div>
        </Link>
      </li>
    ))
  }

  renderThumbnail = (thumbnailUrl, thumbnailCaption) => {
    if (thumbnailUrl)
      return <figure className="BlogPosts__thumbnail">
        <img src={thumbnailUrl} alt={thumbnailCaption}/>
      </figure>
  }

  renderMobileThumbnail = (thumbnailUrl, thumbnailCaption) => {
    if (thumbnailUrl)
      return <figure className="BlogPosts__mobile-thumbnail">
        <img src={thumbnailUrl} alt={thumbnailCaption}/>
      </figure>
  }

  renderTags = (tags) => {
    return tags.map((tag, i) => (
      <li className="BlogPosts__tag" key={'tag-' + i}>{tag}</li>
    ))
  }
}
