import * as React from 'react';
import './Error404Page.css';
import { Link } from "react-router-dom";
import Helmet from "react-helmet/es/Helmet";

export class Error404Page extends React.Component {
  render() {
    return (
      <main className="Error404Page">
        <Helmet>
          <title>Error 404</title>
          <meta name="robots" content="noindex nofollow"/>
        </Helmet>
        <h1 className="Error404Page__header">404</h1>
        <p className="Error404Page__header-description">Page not found.</p>
        <p className="Error404Page__header-action">
          The page you’re looking for doesn’t exist or has been removed.
          Click <Link to="/">here</Link> to go back home.
        </p>
      </main>
    );
  }
}
