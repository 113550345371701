export const BlogPosts = [
  {
    'title': 'The case of popup boxes',
    'slug': 'design-tips-popup-box-why-bad-for-users',
    'thumbnail': 'https://s3-ap-southeast-1.amazonaws.com/images.mgondowijoyo.com/blog/Modal+Box.png',
    'thumbnailCaption': 'An illustration of popup box',
    'tags': ['ux', 'design'],
    'summary': 'This article discusses the usage of popup boxes, how is it bad for your users, where popups can help, and the tips on how-to design good one the time comes when we need to create one.',
    'timestamp': 'August 22, 2019',
    'content': `
<p>
  Popup boxes probably one of the most ubiquitous component manifesting in various shapes in variety of platform, from operating systems, mobile app, web app, and so on. Not to mention the ease of invoking it by just running <code>alert(‘Hello’)</code> in browsers’ JavaScript. (Please don’t.)
</p>
<p>
  This post is my take on the popup box components, why I think we can do better, and where it shouldn’t be replaced. Despite discussing system libraries or OS popup boxes, the main concept is built upon web application popup ideas.
</p>
<h2>Why popups could be bad for your users</h2>
<p>
  It could take just one badly-designed and poorly-timed popup for your customer to leave your page in frustration and go to another competitor’s site, that’s just a few clicks away.
</p>
<p>
  Popups suck because they take the control away from the users and shove the information right in front of the users’ faces. And it’s worse if it’s an ad. It’s like when you’re browsing something in a store, then a salesperson yank you away and shove the promotional poster right in your face. Yes you can just walk away from that condition (by clicking outside the popup box) and continue browsing like nothing ever happened. But there’s a lot of popup boxes that won’t dismiss automatically that way.
</p>
<blockquote>
  Popup boxes are so annoying that we even subconsciously training ourselves to be better at dismissing it. We just skim it real fast and go for the corners seeking the close button.
</blockquote>
<p>
  That’s why if we have to use and design one, we have to design it good, and time it well. The next section will discuss on what we can improve when we design those popup boxes.
</p>
<h2>Things to consider when creating a popup</h2>
<h3>Follow the convention</h3>
<p>
  Keep the close button visible and leave it on the topmost corner. Make it clear and easily distinguishable from the rest of the content.
</p>
<p>
  If you’re designing popup for operating system programs, do not customise it too much. Be sure to follow their HCI guidelines. For example, when styling macOS popups, style it like an actual macOS popup, use their system colour variables (since users can customise the accent colour and toggling the Dark Mode feature).
</p>
<p>
  For non-critical information, be sure to make it dismissible when the user clicks outside of the boundary of the popup box. 
</p>
<h3>Keep it short and simple</h3>
<p>
  Like everything else, make the purpose of the popup box clear and succinct. Use appropriate title and body. If it gets really short or just telling the user that something has been done that the user expects it to be done, how about not using dialog box at all? We can deliver the information in self-dismissing notification-style popup at the edge that might cause less intrusion to the user—after all, that action is what they expect to happen.
</p>
<figure>
<img
  class="regular"
  src="https://s3-ap-southeast-1.amazonaws.com/images.mgondowijoyo.com/blog/Sample+-+Notification.png"
  alt="Example of notification design"
/>
<figcaption>An example of notification-style popup</figcaption>
</figure>
<h3>In web browsers, avoid using the system alert</h3>
<p>
  Such as invoking popup via JavaScript <code>alert()</code> in browsers. It halts the interaction to your website, not to mention it’s very jarring and feels really cheap, and it can be disabled by the user permanently (by checking prevent this website to show more popup). The system popup also might create abrupt diversion to the user when typing something outside the browser window when the web app suddenly comes into focus.
</p>
<p>
  Perhaps the exception is the stay/leave system popup when the user left something unresolved on the page when they are about to leave—but use this as last resort.
</p>
<h3>Use it sparingly</h3>
<p>
  By bombarding your user with incessant popups, they will teach themselves to dismiss it instinctively once it pops to the screen (if they are not leaving yet). This could be problematic once the real critical information pops up.
</p>
<h2>Where popups are acceptable</h2>
<p>
  Due the nature of the popup boxes, it’s well-suited for something that needs the immediate attention from your user (definitely not your ad), such as when there’s a critical system error. In fact, this is one of those cases when using popup is really appropriate. 
</p>
<p>
  Popups are also acceptable as an extension the action when user clicks something, for example when you reject something and you need to give a reason in a small textarea, because it would be too much of an effort for your users when the page has to be redirected to another just to give one.</p>
<p>
  Another good case is when user clicks on an image thumbnail. We can load and show the detailed image in a popup rather than redirecting them to just show an image, especially when they have unfinished business on previous page, such as when they are filling out forms or comparing something. The popup can help them to stay in their context.
</p>
<h2>Conclusion</h2>
<p>
  Well, that’s what I think for popup boxes for now. Perhaps, next time I will discuss about the alternative for popup boxes on several occasions, such as when confirming something.
</p>
<p>
  I hope this posts can help you in a way or two when designing and placing popup boxes.
</p>
<p>
  But at the end of the day, if your statistics show you by adding popup you can get 10% more of revenue and you think it’s worth annoying your user, then you do what you gonna do. Just be sure that if you have to do it, do it in informed way rather than inadvertently.
</p>
<p>
  Anyway, I would really like to hear your opinion regarding this matter. Just send me an email, or tap me on the back if you spot me around.
</p>

`
  },
  {
    'title': 'Handling custom routing in AWS S3 with AWS Cloudfront',
    'slug': 'handling-custom-routing-s3-aws-cloudfront',
    'tags': ['aws', 'blog', 'hosting', 'react', 'cloudfront'],
    'summary': 'So you’ve hosted your static React website in the S3 in cost efficient manner. You might notice that all non-homepage direct request will break the routing. This guide will help you handle such cases with AWS Cloudfront.',
    'timestamp': 'August 18, 2019',
    'content': `
<p>
  Okay, in the previous post we’ve discussed on how to host your React static website using AWS S3 for cost-efficiency. This post will guide you on solving the <code>https</code> security issue and the case where your visitors visited your subpages directly without visiting your homepage first.</p>
<h2>A little bit of background</h2>
<h3>Why’s the route breaking?</h3>
<p>
  Because AWS can’t resolve a bucket with that address. Remember, we handled our routing inside the react-router component, which is mounted in the <code>index.html</code>.
</p>
<p>
  In short, we need to get the <code>index.html</code> first so the path can be resolved using the router.
</p>
<h3>AWS CloudFront to the rescue</h3>
<p>
  AWS CloudFront is a content delivery network. It delivers the data to the requester globally in a fast and highly-redundant manner. They have servers across the globe so the requester can get the files from the node closest to them.
</p>
<h3>What does it have to do with my routing?</h3>
<p>
  Using Cloudfront, we can customise our error pages when the request returns an error. In this case, we’re going to route the 404 error from failing to finding our bucket, to <code>index.html</code> file, so our app can properly resolve this.
</p>
<h2>Setting up your Cloudfront Distribution</h2>
<h3>Step 1 – Create the distribution</h3>
<p>
  To create the distribution, open the Cloudfront page then click on <strong>Create Distribution</strong>, then click <strong>Get Started</strong> on Web section.
</p>
<h3>Step 2 – Fill in the information</h3>
<p>
  Before filling anything, open AWS S3 in a new tab, then go to your bucket. Click on <strong>Properties</strong> tab, then click on <strong>Static Website Hosting</strong>. Copy your bucket endpoint on the top of the modal.
</p>
<p>
  Back to the Cloudfront tab, paste the endpoint into <strong>Origin Domain Name</strong>. The <strong>Origin ID</strong> will be generated for you automatically.
</p>
<p>
  On <strong>Viewer Protocol Policy</strong>, choose <strong>Redirect HTTP to HTTPS</strong>. This will redirect all HTTP requests to HTTPS.
</p>
<p>
  On <strong>Cache Based on Selected Request Headers</strong>, choose <strong>Whitelist</strong>. Then on the newly opened <strong>Whitelist Headers</strong>, choose <strong>Origin</strong> and click <strong>Add</strong> to add it to the ruleset.
</p>
<p>
  On <strong>Compress Object Automatically</strong>, select <strong>Yes</strong> to improve the efficiency by compressing your assets files.
</p>
<p>
  On <strong>Alternate Domain Name (CNAMEs)</strong>, fill in your domain name, or skip this part if you’re not using custom domain.
</p>
<p>
  On <strong>SSL Certificate</strong> leave it on <strong>Default CloudFront certificate</strong> if you’re not using custom domain. If you are using one, you need to create the public certificate first for your domain in <strong>Certificate Manager</strong>.
</p>
<p>
  When you’re done, click <strong>Create Distribution</strong>. If you’re encountering certificate error, make sure the certificate is in accord with your domain name. The distribution will take some time to be set up. Meanwhile, we can move onward to solve the routing error issue.
</p>
<h2>Customising the error page</h2>
<p>
  Click the ID of your distribution to customise it. Then go to <strong>Error Pages</strong> tab. Click <strong>Create Custom Error Response</strong>.
</p>
<p>
  On <strong>HTTP Error Code</strong> dropdown, select <strong>404: Not Found</strong>. Then on <strong>Customise Error Response</strong> choose <strong>Yes</strong>. Two new fields will appear below.
</p>
<p>
  On <strong>Response Page Path</strong>, type in <code>/index.html</code> or wherever your <code>index.html</code> is in. On <strong>HTTP Response Code</strong>, select <strong>200: OK</strong>, then click <strong>Create</strong>.
</p>
<p>
  And we’re done! All we need is to wait for the distribution to be online.
</p>
<h2>You’ve got a new address</h2>
<p>
  Now, that we have created distribution to handle your web app hosted on S3, we need to go to the distribution (CloudFront) address when we want to visit our site hosted in the S3.
</p>
<p>
  Back on CloudFront Distribution page, look for the column named <strong>Domain Name</strong> next to the distribution ID.
</p>
<p>
  This is your new web address. Be sure to visit your web via this address or else the distribution will be skipped entirely. If you have a custom domain, make sure they point to this new address instead of the old one.
</p>
<h2>Updating and invalidating the cache</h2>
<p>
  Since CloudFront caches your website data on their own server for efficiency, you need to invalidate those whenever you update your website content.
</p>
<p>
  You only need to do this if you’ve updated your website data after the distribution creation.
</p>
<p>
  Open your distribution by clicking the ID, then click <strong>Invalidations</strong> tab. From there, click <strong>Create Invalidation</strong>.
</p>
<p>
  Now you need to enter all those files you’ve updated, or just use asterisk (<code>*</code>) to indicate just invalidate everything. Click <strong>Invalidate</strong>, then wait for a few minutes for it to refresh your content.
</p>
<h2>Conclusion</h2>
<p>
  Now we got our website up and running properly with HTTPS and with proper routing! Congratulations!
</p>
<p>
  Perhaps the next step we need to add some sort SEO to make our website friendlier to the indexing bots, and a new domain if you haven’t got one. You can’t hope for your visitors to remember your CloudFront distribution address, can you?
</p>
`
  },
  {
    'title': 'Cost-efficient static website with AWS S3 and React',
    'slug': 'cost-efficient-static-website-aws-s3-react',
    'thumbnail': 'https://s3-ap-southeast-1.amazonaws.com/images.mgondowijoyo.com/blog/AWS+S3.png',
    'thumbnailCaption': 'Three cubes stylized as AWS S3 logo',
    'tags': ['aws', 'blog', 'hosting', 'react'],
    'summary': 'Spend fractions of the EC2 cost by hosting your static React website in the AWS S3. This post will try to explain the steps necessary and making sure your project fits the case.',
    'timestamp': 'July 31, 2019',
    'content': `
<p>
  So, you’ve come here in search of the tutorial how to host your static website on the AWS S3 (Simple Storage Service).
  You might be interested in the cheaper part of hosting yours in AWS S3—or you might even stumble your way here. In any case, welcome to my first post.
</p>
<p>
  This article is divided to three section. First, introduction and preparation, second, the steps necessary, and the last will be the conclusion.
</p>
<h2>Before we go</h2>
<p>
  Okay, before proceeding, here are the checklist whether this solution is suitable for you:
  <ul>
    <li>You’re hosting static website that doesn’t require backend or SSR,</li>
    <li>You have a React project ready with <code>react-router</code> configured,</li>
    <li>You already have an AWS account, and</li>
    <li>You don’t need to update your app frequently.</li>
</ul>
</p>
<p>
  If your project checks them all, we’re good to go. I’m going to give example based on the default create-react-app.
  If your project is using customised version of React, you might need to adjust the steps accordingly.
</p>
<h2>The steps necessary</h2>
<p>
  AWS S3 is a object-storage service that offers scalability and high-availability with competitive pricing. From my experience, it reminds me of Dropbox and the transfer process is like classic FTP.
</p>
<h3>Step 1 – Build your React app</h3>
<p>
  First of all, you need to build your react app. You can build it by running <code>npm run build</code> and it will spit your production-ready files inside the build folder.
</p>
<h3>Step 2 – Create your bucket</h3>
<figure>
<img
  class="regular"
  src="https://s3-ap-southeast-1.amazonaws.com/images.mgondowijoyo.com/blog/Screenshot+AWS+S3.png"
  alt="Screenshot of AWS S3 interface"
/>
<figcaption>The AWS S3 dashboard interface</figcaption>
</figure>
<p>
  Bucket is like a collection of your object. You need to create one for your project. Head to the S3 page then click <strong>+ Create Bucket</strong>. Type in the bucket name (I suggest your would-be domain name) and the region for the bucket.
  Theoritically, you’ll get less latency accessing your bucket if it’s physically closer to you.
</p>
<p>
  Then click <strong>Next</strong>, then <strong>Next</strong> again, then uncheck <strong>Block all public access</strong>. We want it to be accessible to the public. Click <strong>Next</strong> once again, then click <strong>Create Bucket</strong>.
</p>
<p>
  There you go. Your bucket is up and ready!
</p>
<h3>Step 3 – Uploading your files</h3>
<p>
  Now click the upload button and upload your files inside the build folder. You can upload multiple files and folders directly by dragging it into the interface. You need to set up the paths to mirror the path in your build folder.
</p>
<h3>Step 4 – Configuring the bucket</h3>
<p>
  Now that you’ve uploaded your build files, it’s time to set up your bucket. Click the <strong>Permission</strong> tab. From there, click <strong>Static website hosting</strong>. Check the radio button for <strong>Use this bucket to host a website</strong>.
</p>
<p>
  Now the option expands and show a few of textboxes. On the Index document field, type in <code>index.html</code>. This will tell the bucket to serve index.html whenever the bucket is accessed. Then click <strong>Save</strong>.
</p>
<h3>Step 5 – Testing your website</h3>
<p>
  Now comes the exciting part, seeing your website up and alive! Click again on <strong>Static website hosting</strong>. See the topmost of the form, you’ll see <strong>endpoint</strong>, with a web address beside it. This is your bucket address. Click on it, then you should be able to see and browse your website.
</p>
<figure>
<img
  class="regular"
  src="https://s3-ap-southeast-1.amazonaws.com/images.mgondowijoyo.com/blog/Screenshot+S3+Bucket+Endpoint.png"
  alt="Screenshot of bucket endpoint"
/>
<figcaption>Your bucket endpoint location</figcaption>
</figure>
<h2>Conclusion</h2>
<p>
  And, we’re done! We’ve managed to upload our static React website to the AWS S3. But we’ve not done yet, as you might discover, there’s few caveats here.
</p>
<h3>The caveats</h3>
<p>
  First, the URL is unwieldy. We can solve this by registering our own domain (I registered myself on <strong>AWS Route 53</strong> just to avoid the hassle). Once we registered our domain, we can create alias to point the address to our bucket address.
</p>
<p>
  Second, you might notice that whenever we refresh the page that’s not the homepage, the website will break. This is caused when the bucket can’t resolve the address you entered, and this is expected behaviour. We can mitigate this problem by using another AWS service called <strong>AWS Cloudfront</strong>, which I’ll discuss later on the next post.
</p>
<p>
  Third, it’s not encrypted. With the latest push for security from big software vendors, you don’t want your website to show big text <strong>Not secure</strong> on your users’ browsers. We can solve this by creating certificate and configure them on AWS Cloudfront. Again, this will be another topic for another post.
</p>
<p>
  And for now, that would be all! I hope the tutorial helps you somehow, and if you find this article helpful, feel free to share, especially for your developer friends who might benefits from this! I’ll see you guys later!
</p>
    `
  }
];
