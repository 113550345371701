import * as React from 'react';
import { Link } from "react-router-dom";
import './NavigationBar.css';
import { useEffect, useState } from "react";

export function NavigationBar(props) {
  const MIN_NAV_HEIGHT = 50;
  const MAX_NAV_HEIGHT = 80;
  const [scrollY, setScrollY] = useState(window.scrollY || 0);
  const getLinkClassName = (path, currentPath) => {
    let className = "NavigationBar__link";
    if (path === currentPath || (currentPath === '/' && path === '/about')) {
      className += " NavigationBar__link--active";
    }
    return className;
  }
  useEffect(() => {
    const scrollHandler = () => {
      setScrollY(window.scrollY);
    }
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  })
  console.log(props.currentPath);

  return (
    <>
      <nav className="NavigationBar" style={{ "height": Math.max(MIN_NAV_HEIGHT, MAX_NAV_HEIGHT - scrollY) }}>
        <div className="NavigationBar__header-container" style={{
          "height": Math.max(MIN_NAV_HEIGHT, MAX_NAV_HEIGHT - scrollY),
          "transform": "scale(" + Math.min((1 - (Math.min(scrollY, 30)) / 100), 1) + ")",
          "marginRight": Math.min((-1 * Math.min(32, scrollY)), 0)
        }}>
          <Link className="NavigationBar__header" to="/">
            <span>Martin</span>
            <span>Gondowijoyo</span>
          </Link>
        </div>
        <div className="NavigationBar__link-container">
          <Link className={getLinkClassName("/about", props.currentPath)} to="/about">
            About
          </Link>
        </div>
        <div className="NavigationBar__link-container">
          <Link className={getLinkClassName("/blog", props.currentPath)} to="/blog">
            Blog
          </Link>
        </div>
      </nav>
      <div className="NavigationBar__offset"/>
    </>
  );
}
